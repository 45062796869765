import { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { Button, TextField, Card, Alert, Typography, Container, Box } from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import axiosInstance from '../lib/axiosInstance';
import useNavigateWrapper from '../hooks/useNavigateWrapper';

export default function LoginForm() {
  const { goToTables } = useNavigateWrapper();
  const [errorText, setErrorText] = useState('');
  const { login } = useContext(AuthContext);

  const sendTokensToWebView = (accessToken, refreshToken) => {
    if (window.ReactNativeWebView) {
      console.log('Отправляю tokens ивент');
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'tokens',
          data: {
            accessToken,
            refreshToken,
          },
        })
      );
    }
  };

  const formik = useFormik({
    initialValues: { username: '', password: '' },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const { data } = await axiosInstance.post('/v1/auth/login', {
          username: values.username,
          password: values.password,
          deviceToken: localStorage.getItem('deviceToken'),
        });
        sendTokensToWebView(data.tokens.access.token, data.tokens.refresh.token);
        login(data.tokens.access.token, data.tokens.refresh.token);
        goToTables();
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status) {
          setErrorText('Логин или пароль не правильный');
        } else {
          setErrorText('Произошла ошибка при попытке входа');
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Container>
      <Box
        component="img"
        sx={{
          marginTop: '20px',
          width: '60%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
        alt="Logo"
        src="/logo-for-web.png"
      />
      <Card
        sx={{
          width: '80%',
          margin: 'auto',
          padding: '16px',
          marginTop: '24px',
          boxShadow: 'none'
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <TextField
            name="username"
            label="Логин"
            variant="outlined"
            margin="normal"
            fullWidth
            value={formik.values.username}
            onChange={formik.handleChange}
          />
          <TextField
            name="password"
            type="password"
            label="Пароль"
            variant="outlined"
            margin="normal"
            fullWidth
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          <Button
            type="submit"
            variant="contained"
            style={{ backgroundColor: '#2D9596' }}
            disabled={formik.isSubmitting}
            fullWidth
            sx={{ marginTop: '16px' }}
          >
            Войти
          </Button>
        </form>
        {errorText && (
          <Alert severity="error" sx={{ marginTop: '16px' }}>
            <Typography>{errorText}</Typography>
          </Alert>
        )}
      </Card>
    </Container>
  );
}
