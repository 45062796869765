import axios from 'axios';
let instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const sendLogoutEventToWebView = () => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'logout' }));
  }
};

export const setupResponseInterceptor = (navigate) => {
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
    
      if (error.response.status === 401 && !originalRequest._retry && originalRequest.url !== '/v1/auth/login' && originalRequest.url !== '/v1/auth/refresh-tokens') {
        originalRequest._retry = true;
        try {
          const refreshToken = localStorage.getItem('refreshToken');
          const response = await instance.post('/v1/auth/refresh-tokens', { refreshToken });
          const { access, refresh } = response.data;
  
          localStorage.setItem('accessToken', access.token);
          localStorage.setItem('refreshToken', refresh.token);
  
          // Retry the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${access.token}`;
          return instance(originalRequest);
        } catch (error) {
          console.log(error);
          sendLogoutEventToWebView();
          navigate();
          return Promise.reject(error);
        }
      }
      return Promise.reject(error);
    }
  )
}

export default instance;
