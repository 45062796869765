import { useState, useEffect, useContext } from 'react';
import './App.css';
import { AuthContext } from './context/AuthContext';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
import { setupResponseInterceptor } from './lib/axiosInstance';
import useNavigateWrapper from './hooks/useNavigateWrapper';

// ----------------------------------------------------------------------

function App() {
  const { goToLogin, goToTables } = useNavigateWrapper();
  const [isLoaded, setIsLoaded] = useState(false);

  const { login } = useContext(AuthContext);

  useEffect(() => {
    const handleMessage = (event) => {
      console.log(`Принял webviewdata ивент: ${JSON.stringify(event?.detail)}`);
      if (!event) {
        return;
      }
  
      if (!event.detail) {
        return;
      }
  
      const parsedData = JSON.parse(event.detail);
  
      if (parsedData.deviceToken) {
        localStorage.setItem('deviceToken', parsedData.deviceToken);
      }
      if (parsedData.accessToken && parsedData.refreshToken) {
        // Use login from AuthContext to update state
        login(parsedData.accessToken, parsedData.refreshToken);
        goToTables();
      }
    };

    // Add the event listener when the app mounts
    console.log('Adding webviewdata listener');
    window.addEventListener('webviewdata', handleMessage);
    sendReadyMessage();

    // Cleanup the event listener when the app unmounts
    return () => {
      console.log('Removing webviewdata listener');
      window.removeEventListener('webviewdata', handleMessage);
    };
  }, [login, goToTables]);

  const sendReadyMessage = () => {
    if (window.ReactNativeWebView) {
      console.log('Отправляю ready ивент');
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'ready' }));
    }
  };

  

  if (!isLoaded) {
    setIsLoaded(true);
    setupResponseInterceptor(goToLogin);
  }

  return (
    <ThemeProvider>
      <Router />
    </ThemeProvider>
  );
}

export default App;
