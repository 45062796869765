import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

import LoginPage from '../pages/LoginPage';

export default function TokenGuard({ children }) {
  const { isLoggedIn, loading } = useContext(AuthContext);

  if (loading) {
    return null;
  }

  if (!isLoggedIn) {
    return <LoginPage />;
  }

  return children;
}
