import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useNavigateWrapper from '../hooks/useNavigateWrapper';
import axiosInstance from '../lib/axiosInstance';
// @mui
import { Container, Typography, Stack, Button } from '@mui/material';
import { ORDER_ACTIONS, ORDER_STATUS, ORDER_FINAL_STATUSES } from '../constants';

import OrderGroup from '../components/OrderGroup';
import TableActions from '../components/TableActions';

import dayjs from 'dayjs';
import 'dayjs/locale/ru';
dayjs.locale('ru');

// ----------------------------------------------------------------------

export default function Table(props) {
  const { tableId } = useParams();
  const [checkedOrders, setCheckedOrders] = useState({});
  const [table, setTable] = useState();
  const { orders } = table || [];

  const getApiData = useCallback(async () => {
    try {
      const tableRes = await axiosInstance.get(`/v1/tables/${tableId}`);
      setTable(tableRes.data);
    } catch (error) {
      console.error(error);
    }
  }, [tableId]);

  useEffect(() => {
    getApiData();

    const interval = setInterval(() => {
      getApiData();
    }, 15000);

    return () => clearInterval(interval);
  }, [getApiData]);

  const { refresh, goBack } = useNavigateWrapper();

  if (!table) {
    return <div>Loading...</div>
  }

  const checkOrderAction = (orders) => (event) => {
    if (event.target.checked){
      setCheckedOrders({
        ...checkedOrders,
        [orders[0].menuItemVariantId + orders[0].status]: orders
      });
    } else {
      setCheckedOrders(current => {
        const newItems = { ...current };
        delete newItems[orders[0].menuItemVariantId + orders[0].status];
        return newItems;
      });
    }
  }

  const checkAllAction = (orderGroupOrders) => (event) => {
    if (event.target.checked){
      setCheckedOrders(current => {
        const newItems = { ...current };
        orderGroupOrders.forEach(orders => {
          if (!ORDER_FINAL_STATUSES.includes(orders[0].status)) {
            newItems[orders[0].menuItemVariantId + orders[0].status] = orders
          }
        });
        return newItems;
      });
    } else {
      setCheckedOrders(current => {
        const newItems = { ...current };
        orderGroupOrders.forEach(orders => {
          delete newItems[orders[0].menuItemVariantId + orders[0].status];
        });
        return newItems;
      });
    }
  }

  function isChecked(orders) {
    return Boolean(checkedOrders[orders[0].menuItemVariantId + orders[0].status]) || ORDER_FINAL_STATUSES.includes(orders[0].status);
  }

  function areAllChecked(orderGroupOrders) {
    return orderGroupOrders.every(isChecked);
  }

  function areCheckedOrdersInStatus(status) {
    const ordersGrouped = Object.values(checkedOrders);
    if (ordersGrouped.length === 0) {
      return false;
    }
    return ordersGrouped.flat().every(order => order.status === status);
  }

  async function changeStatus(action) {
    try {
      const orderIds = Object.values(checkedOrders).flat().map(order => order._id);
      await axiosInstance.post('/v1/orders/manipulate', {
        orderIds,
        action
      });

      refresh();
    } catch (error) {
      console.log(error);
    }
  }

  const groupedByOrderGroup = orders.reduce((acc, order) => {
    if (acc[order.orderGroupId]) {
      acc[order.orderGroupId].push(order);
    } else {
      acc[order.orderGroupId] = [order];
    }

    return acc;
  }, {});
  const arrGroupedByOrderGroup = Object.values(groupedByOrderGroup);

  const groupedByMenuItemVariantAmountAndStatus = arrGroupedByOrderGroup.map(orderGroupMenuItems => {
    const groupedByMenuItemVariantWithAmountAndStatus = orderGroupMenuItems.reduce((acc, order) => {
      const key = order.menuItemVariantId + order.status;
      if (acc[key]) {
        acc[key].push(order);
      } else {
        acc[key] = [order];
      }

      return acc;
    }, {});

    return Object.values(groupedByMenuItemVariantWithAmountAndStatus);
  });

  // console.log(groupedByMenuItemVariantAmountAndStatus);

  return (
    <Container sx={{ paddingBottom: '140px' }}>
      <Stack direction='row' spacing={1} p={2} pt={3} justifyContent='space-between' alignItems='center'>
        <Typography fontWeight={700} fontSize={20}>
          Стол #{table.number}
        </Typography>
        {orders.length > 0 && <TableActions orders={orders} />}
      </Stack>

      {orders.length === 0 && <Typography>За данным столом нет заказов</Typography>}

      <Stack spacing={2}>
        {arrGroupedByOrderGroup.map((orderGroupMenuItems, index) => (
          <OrderGroup
            orders={groupedByMenuItemVariantAmountAndStatus[index]}
            createdAt={orderGroupMenuItems[0].createdAt}
            checkOrderAction={checkOrderAction}
            isChecked={isChecked}
            areAllChecked={areAllChecked}
            checkAllAction={checkAllAction}
            key={index}
          />
        ))}
      </Stack>
      <Stack
        sx={{
          position: 'fixed',
          bottom: 0,
          left: '5%',
          width: '90%',
          paddingBottom: '10px',
          paddingTop: '10px',
          backgroundColor: 'white'
        }}
        gap={1}
        direction='column'
      >
        <Stack gap={1} direction='row'>
          {table.orders.length > 0 && (
            <>
              <Button
                color='error'
                variant="outlined"
                fullWidth
                onClick={() => changeStatus(ORDER_ACTIONS.decline)}
                disabled={!areCheckedOrdersInStatus(ORDER_STATUS.pending)}
              >
                <Typography fontWeight={600} fontSize={14}>Отклонить</Typography>
              </Button>
              <Button
                color='success'
                variant='outlined'
                fullWidth
                onClick={() => changeStatus(ORDER_ACTIONS.execute)}
                disabled={!areCheckedOrdersInStatus(ORDER_STATUS.accepted)}
              >
                <Typography fontWeight={600} fontSize={14}>Исполнить</Typography>
              </Button>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => changeStatus(ORDER_ACTIONS.accept)}
                disabled={!areCheckedOrdersInStatus(ORDER_STATUS.pending)}
              >
                <Typography fontWeight={600} fontSize={14}>Принять</Typography>
              </Button>
            </>
          )}
        </Stack>
        <Button
          variant="outlined"
          fullWidth
          onClick={() => goBack()}
        >
          <Typography fontWeight={600} fontSize={14}>Назад</Typography>
        </Button>
      </Stack>
    </Container>
  );
}
